import { NextPageContext } from 'next';
import { getProviders, useSession } from 'next-auth/react';
import React, { useEffect, useState } from 'react';
import SignInWithProvider from 'components/common/auth/signInWithProvider';
import type { NextAuthProvider } from 'components/common/auth/types';
import Body from 'components/common/body';
import Container from 'components/container';
import PublicLayout from 'components/layouts/Public';
import { AuthError, getTextForError } from 'constants/auth';
import { FEED_URL, SIGNIN_URL } from 'constants/urls';
import { useStaticRouter } from 'utils/useStaticRouter';
import WithWebviewChecks from 'utils/withWebviewChecks';

interface SignInPageProps {
  userAgent?: string;
}

const SignInPage = ({ userAgent }: SignInPageProps) => {
  const router = useStaticRouter();
  const { nextUrl, error } = router.query;
  const [providers, setProviders] = useState<NextAuthProvider | null>(null);
  const { data, status } = useSession();

  const isSessionLoading = status === 'loading';

  useEffect(() => {
    getProviders().then(setProviders);
  }, []);

  if (data?.user) {
    router.replace((nextUrl as string) || FEED_URL);
    return null;
  }

  return isSessionLoading ? null : (
    <WithWebviewChecks userAgent={userAgent} redirectPath={SIGNIN_URL}>
      <PublicLayout title="Sign In">
        {error && <Body>{getTextForError(error as AuthError)}</Body>}
        <Container centerContent py="20px" pylg="20px">
          {providers && <SignInWithProvider providers={providers} />}
        </Container>
      </PublicLayout>
    </WithWebviewChecks>
  );
};

SignInPage.getInitialProps = async (ctx: NextPageContext): Promise<{ userAgent?: string }> => {
  const userAgent =
    typeof window === 'undefined' ? ctx.req?.headers['user-agent'] : window.navigator.userAgent;

  return { userAgent };
};

export default SignInPage;
